html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Typewriter {
  display: inline;
}

/* chat bubbles */
.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 10%;
  background-color: #eee;
  position: relative;
  text-align: left;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}

.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 10%;
  background: rgb(0, 120, 254);
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: rgb(0, 120, 254);
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

.bggrad::before {
  content: "";
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0px;
  z-index: -1;
  background: #fff;
  background: linear-gradient(-135deg, #6aaaee27, #fff);
}

.video-container {
  position: absolute;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  top: 0;
  left: 0;
  width: 100%;
}

.video-container iframe {
  position: absolute;
  top: 25px;
  left: 22px;
  width: 94%;
  height: 95%;
}

.video-container-full {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.video-container-full iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* tbody>tr:last-child td {
  background: black;
  color: white;
  font-weight: 700;
}

tbody.fullScreen>tr:last-child td {
  font-size: 3em;
}

*/

.nextUp tbody tr:last-child {
  display: none;
}

input[type="file"] {
  display: none;
}

.parentScroll {
  border: "2px solid blue";
  overflow: auto;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(300px);
  scroll-snap-type: y mandatory;
}

.childScroll {
  scroll-snap-align: center;
}

@keyframes createBox {
  from {
    transform: scale(0.4);
  }

  to {
    transform: scale(0.5);
  }
}
